import { useCallback } from 'react';
import { AnalyticsBrowser, UserTraits } from '@segment/analytics-next';

import { User } from 'app/typings';

export const useSegmentIdentify = (analytics: AnalyticsBrowser) => {
  return useCallback(
    (user?: User | null, identifyTraits: UserTraits = {}) => {
      if (user) {
        const { id, firstName, lastName, email } = user;

        const name =
          firstName && lastName ? `${firstName} ${lastName}` : undefined;

        analytics.identify(id.toString(), {
          source: 'client',
          visitor_type: 'fan',
          name,
          email,
          ...identifyTraits,
        });
      }
    },
    [analytics]
  );
};
